import axios from 'axios';
import router from '../router';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Debugiranje
        console.log('Error object:', error);
        console.log('Error response:', error.response);
        console.log('Error status:', error.response?.status);

        if (error.response && error.response.status === 401) {
            console.log('Token expired at:', new Date().toISOString());
            localStorage.removeItem('user');

            if (router.currentRoute.value.name !== 'login') {
                router.push({ name: 'login' }).then(() => {
                    alert('Vaša seja je potekla. Prosimo, prijavite se ponovno.');
                    window.location.reload();
                });
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
