import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import "./assets/main.css";

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import axiosInstance from './services/axios-interceptors';

const app = createApp(App);

app.config.globalProperties.$http = axiosInstance; // Uporabite axiosInstance kot privzeto Axios instanco

app.use(router)
    .use(store)
    .use(ElementPlus)
    .mount("#app");