<template>
  <div>
    <!-- Glavna vsebina brez stranske navigacije -->
    <div class="empty-layout">
      <slot></slot> <!-- Tu se bo prikazala panorama -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
  // ostala koda ...
};
</script>

<style>
.empty-layout {
  /* Prilagodite slog, če je potrebno */
}
</style>