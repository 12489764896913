<template>
  <!-- Dinamično prikazovanje layouta na podlagi trenutne poti -->
  <div v-if="$route.meta.layout">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import axios from 'axios'; // Uvoz Axios-a, če še ni uvožen

export default {
  computed: {
    layout() {
      const currentRouteLayout = this.$route.meta.layout;
      // Direktno vrni ustrezen layout na podlagi trenutne poti
      if (currentRouteLayout) {
        return currentRouteLayout;
      } else {
        return DefaultLayout;
      }
    }
  },
  created() {
    // Dodaj interceptor za lovljenje 401 napak
    axios.interceptors.response.use(
        response => response, // Če je odgovor uspešen, ga vrni naprej
        error => {
          if (error.response && error.response.status === 401) {
            console.log('Token expired, logging out user');
            // Obvesti Vuex store o potekli seji
            this.$store.dispatch('auth/handleExpiredToken');
            // Preusmeri na login stran
            this.$router.push('/login');
            // Osveži stran za počistitev vseh podatkov
            window.location.reload();
          }
          return Promise.reject(error);
        }
    );
  }
}
</script>
