import AuthService from '../services/auth.service';
import router from '../router';

// Pridobi uporabnika iz localStorage, če obstaja
const user = JSON.parse(localStorage.getItem('user'));

// Začetno stanje modula
const initialState = {
  status: {
    loggedIn: Boolean(user)
  },
  user: user || null
};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
          user => {
            commit('loginSuccess', user);
            return Promise.resolve(user);
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
      router.push('/login'); // Preusmeri na login stran
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
          response => {
            commit('registerSuccess');
            return Promise.resolve(response.data);
          },
          error => {
            commit('registerFailure');
            return Promise.reject(error);
          }
      );
    },
    handleExpiredToken({ commit }) {
      commit('logout'); // Počisti stanje uporabnika
      localStorage.removeItem('user'); // Počisti shranjenega uporabnika iz localStorage
      router.push('/login'); // Preusmeri na login stran
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      console.log('User roles:', user.roles); // Dodano za preverjanje vlog uporabnika
      localStorage.setItem('user', JSON.stringify(user)); // Shranjevanje uporabnika v localStorage
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
